<template>
  <div class="register-container">
    <img src="/img/register.png" class="register-image" alt="Recicla" />
    <div class="register-content">
      <h2>Registro</h2>
      <Steps :step.sync="step" @update="s => (step = s)"></Steps>
      <form class="register-content-form">
        <template v-if="step === 1">
          <h3>Dados do gerador</h3>

          <div
            class="d-flex items-center justify-around flex-wrap register-type"
          >
            <p class="text-center full-width">
              Onde é gerado seu material?
            </p>

            <div
              @click="form.type = 1"
              class="d-flex column full-width justify-center register-type-item"
              :class="{ selected: form.type === 1 }"
            >
              <img src="/svg/residencia.svg" />
              <p>
                minha <br />
                <span>Residência</span>
              </p>
            </div>
            <div
              @click="form.type = 2"
              class="d-flex column full-width justify-center register-type-item"
              :class="{ selected: form.type === 2 }"
            >
              <img src="/svg/empresa.svg" />
              <p>
                minha <br />
                <span>Empresa</span>
              </p>
            </div>
          </div>

          <r-input
            v-if="form.type == 1"
            v-model="form.name"
            label="Nome"
            :rules="rules.step1.name"
          />
          <r-input
            v-if="form.type == 2"
            v-model="form.fantasy_name"
            label="Nome fantasia"
            :rules="rules.step1.fantasy_name"
          />
          <r-input
            v-if="form.type == 2"
            v-model="form.company_name"
            label="Razão social"
            :rules="rules.step1.company_name"
          />
          <r-input
            v-if="form.type == 1"
            v-model="form.cpf"
            label="CPF"
            mask="###.###.###-##"
            :rules="rules.step1.cpf"
          />
          <r-input
            v-if="form.type == 2"
            v-model="form.cnpj"
            label="CNPJ"
            mask="##.###.###.####/##"
            :rules="rules.step1.cnpj"
          />
          <r-input
            v-if="form.type > 0"
            v-model="form.phone"
            label="Telefone"
            mask="(##) #####-####"
          />
        </template>

        <template v-if="step === 2">
          <h3>Confirme seu endereço</h3>

          <r-input v-model="form.zip_code" label="CEP" mask="#####-###" class="col-12" :class="{ 'col-md-4': form.province_id && form.city_id }" :rules="rules.step2.zip_code" />

          <r-select label="Cidade" v-if="form.city_id" v-model="form.city_id" :readonly="true" :items.sync="cities" class="col-12 col-md-4" labelName="name" />

          <r-select label="Estado" v-model="form.province_id" v-if="form.province_id" :readonly="true" :items.sync="provinces" labelName="name" class="col-12 col-md-4" />

          <r-input v-model="form.number" label="Número" :type="ctrnumbertype" :rules="rules.step2.number" class="col-12 col-md-4" :readonly="ctrnumber" />

          <div class="col-md-4" style="padding-top: 35px">
            <input type="checkbox" id="checkbox" v-model="ctrnumber">

            <label for="checkbox">Sem número</label>
          </div>

          <r-input v-model="form.complemento" class="col-md-4" label="Complemento" :rules="rules.step2.complement" />

          <r-input v-model="form.street" label="Endereço" :rules="rules.step2.street" class="col-12 col-md-12" :readonly="loadedByZip.street" />

          <r-input v-model="form.district" label="Bairro" class="col-12 col-md-12" :rules="rules.step2.district" :readonly="loadedByZip.district" />
        </template>

        <template v-if="step === 3">
          <h3>Informações cadastrais</h3>
          <r-input
            v-model="form.email"
            label="Email"
            type="text"
            :rules="rules.step1.email"
          />
          <r-input
            v-model="form.password"
            label="Senha"
            type="password"
            :rules="rules.step1.password"
            info="A senha deve conter pelo menos 8 caracateres, um símbolo, uma letra e um número"
          />
          <r-input
            v-model="form.repassword"
            label="Confirmar senha"
            type="password"
            :rules="rules.step1.repassword"
          />
          <div class="col-md-12" style="padding-top: 35px">
            <input type="checkbox" id="checkbox" v-model="form.agree">
            &nbsp;
            <label for="checkbox">Ao se cadastrar, você concorda com os termos da nossa <a style="color: green" href="#">política de privacidade.</a></label>
          </div>
        </template>
      </form>
    </div>
    <Actions :step.sync="step" @update="s => (step = s)" @register="register" :loading="loading" />
  </div>
</template>

<script>
import axios from 'axios'
import swal from 'sweetalert'
import registerModel from '@/models/Register'
import { mapGetters, mapActions } from 'vuex'

export default {
  data: () => ({
    phoneMask: ['(##) ####-####', '(##) #####-####'],
    step: 1,
    loading: false,
    type: 1,
    ctrnumber: false,
    ctrnumbertype: 'number',
    form: registerModel.form,
    cities: [],
    loadedByZip: {
      street: true,
      number: false,
      district: true,
      complement: false
    }
  }),
  methods: {
    ...mapActions('province', { fetchProvinces: 'fetch' }),
    async register () {
      if (this.form.email === '' || this.form.password === '' || this.form.repassword === '') {
        await swal({
          title: 'Preencha todos os campos',
          text: 'Todos os campos precisam estar preenchidos',
          icon: 'error'
        })
        return
      }
      if (this.form.password !== this.form.repassword) {
        await swal({
          title: 'As senhas são diferentes',
          text: 'As senhas devem ser iguais para prosseguir',
          icon: 'error'
        })
        return
      }
      this.loading = true
      try {
        const response = await axios.post('/generator', this.parseData())
        await swal({
          title: 'Sucesso!',
          text: response.data.message,
          icon: 'success'
        }).then(async () => {
          registerModel.initForm()
          await this.$router.push('/auth/login')
        })
      } catch (error) {
        await swal({
          title: 'Ooops!',
          text: error.response.data.message,
          icon: 'error',
          button: 'Ok'
        })
      } finally {
        this.loading = false
      }
    },
    parseData () {
      const data = new FormData()
      Object.keys(this.form).map(key => {
        const item = this.form[key]
        if (Array.isArray(item)) {
          let count = 0
          item.map(subitem => {
            Object.keys(subitem).map(ik => {
              data.append(`${key}[${count}][${ik}]`, subitem[ik])
            })
            count++
          })
        } else {
          data.append(key, item)
        }
      })
      return data
    }
  },
  computed: {
    ...mapGetters('province', ['provinces']),
    rules () {
      return registerModel.rules
    }
  },
  watch: {
    async 'form.zip_code' (val) {
      if (!val || val.length < 8) return

      try {
        const response = await axios.get('/cep?cep=' + val)
        this.form = Object.assign(this.form, response.data)

        const responseCities = await axios.get('/cities?province_id=' + this.form.province_id)
        this.cities = responseCities.data
      } catch (error) {
        swal({
          title: 'Ooops!',
          text: 'Falha ao buscas os dados do CEP. Tente novamente',
          icon: 'error',
          button: 'Ok'
        })
      }
    },
    ctrnumber (val) {
      if (val) {
        this.ctrnumbertype = 'text'
        this.form.number = 'S/N'
      } else {
        this.ctrnumbertype = 'number'
        this.form.number = null
      }
    }
  },
  mounted () {
    this.fetchProvinces()
  },
  components: {
    Steps: () => import('./components/steps.vue'),
    Actions: () => import('./components/actions.vue'),
    RInput: () => import('@/components/input.vue'),
    RSelect: () => import('@/components/select.vue')
  }
}
</script>

<style scoped>
.register-type {
  margin: 2.5rem auto;
  width: 350px;
  max-width: 100%;
}

.register-type-item {
  margin-top: 2.5rem;
  text-align: center;
  cursor: pointer;
  border-radius: 1rem;
  width: 10rem;
  padding: 1.5rem;
  transition: 0.3s linear;
}

.register-type-item:hover,
.register-type-item.selected {
  background: #eaf7ed;
}

.register-type-item img {
  margin-bottom: 1.5rem;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.register-type-item span {
  color: #15a548;
  font-weight: bold;
}

.register-container {
  background: #ffffff !important;
  width: 1100px;
  min-height: 800px;
  max-width: 98%;
  border-radius: 0.75rem;
  box-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.15);
  padding: 1.5rem;
  margin: 2rem auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  position: relative;
}

.register-content,
.register-image {
  display: block;
}

.register-content {
  width: 62%;
}

.register-image {
  width: calc(100% - 62% - 2%);
  margin-right: 2%;
  object-fit: scale-down;
  margin-top: 4rem;
}

.register-content h2,
.register-content h3 {
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 3rem;
  width: 100%;
}
.register-content h3 {
  text-align: left;
  margin-bottom: 2rem;
  color: #14a949;
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 0.5rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.register-content-form {
  width: 100%;
  max-width: 96%;
  margin: 0 auto;
  padding: 1rem 0;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 4rem;
}
</style>
